import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { globalState } from '../../../shared/foreground/models';
import { useFaviconUrlFromDoc, useRssSourceNameForDoc } from '../../../shared/foreground/stateHooks';
import { fetchDocumentContent } from '../../../shared/foreground/stateUpdaters/transientStateUpdaters/documentContent';
import { Category, DisplayTheme, FirstClassDocument } from '../../../shared/types';
import { isDocumentWithThirdPartyUrl } from '../../../shared/typeValidators';
import capitalize from '../../../shared/utils/capitalize';
import exceptionHandler from '../../../shared/utils/exceptionHandler.platform';
import getDocumentDomain from '../../../shared/utils/getDocumentDomain';
import getDocumentOverrideOrReal from '../../../shared/utils/getDocumentOverrideOrReal';
import getListeningTime from '../../../shared/utils/getListeningTime';
import getRandomItem from '../../../shared/utils/getRandomItem';
import getUrlDomain from '../../../shared/utils/getUrlDomain';
import useIsInViewport from '../hooks/useIsInViewport';
import galleryBackgroundDark1 from '../images/gallery-backgrounds/bg-gallery-dark-01.png';
import galleryBackgroundDark2 from '../images/gallery-backgrounds/bg-gallery-dark-02.png';
import galleryBackgroundDark3 from '../images/gallery-backgrounds/bg-gallery-dark-03.png';
import galleryBackgroundDark4 from '../images/gallery-backgrounds/bg-gallery-dark-04.png';
import galleryBackgroundLight1 from '../images/gallery-backgrounds/bg-gallery-light-01.png';
import galleryBackgroundLight2 from '../images/gallery-backgrounds/bg-gallery-light-02.png';
import galleryBackgroundLight3 from '../images/gallery-backgrounds/bg-gallery-light-03.png';
import galleryBackgroundLight4 from '../images/gallery-backgrounds/bg-gallery-light-04.png';
import styles from './DocumentCard.module.css';
import DocumentFaviconOrIcon from './DocumentFaviconOrIcon';
import DonutProgressIndicator from './DonutProgressIndicator';
import ArticlesIcon from './icons/ArticlesIcon';
import BooksIcon from './icons/BooksIcon';
import CheckmarkInCircleIcon from './icons/CheckmarkInCircleIcon';
import EmailsIcon from './icons/EmailsIcon';
import PDFIcon from './icons/PDFIcon';
import TwitterIcon from './icons/TwitterIcon';
import VideoIcon from './icons/VideoIcon';
import StatusDot from './StatusDot';
import TimeLeftToRead from './TimeLeftToRead';
import TimeToListen from './TimeToListen';

function reportUnexpectedCategoryError(category: Category, extra: { [p: string]: unknown }) {
  exceptionHandler.captureException(new Error(`Unexpected category for document card icon`), {
    extra: {
      ...extra,
      category,
    },
  });
}

const getIconFromCategory = (
  category: Category,
  props: { [name: string]: unknown } = {},
): JSX.Element => {
  if ([Category.Article, Category.RSS].includes(category)) {
    return <ArticlesIcon {...props} text="" />;
  }
  if (category === Category.Email) {
    return <EmailsIcon {...props} text="" />;
  }
  if (category === Category.EPUB) {
    return <BooksIcon {...props} text="" />;
  }
  if (category === Category.PDF) {
    return <PDFIcon {...props} text="" />;
  }
  if (category === Category.Tweet) {
    return <TwitterIcon {...props} text="" />;
  }
  if (category === Category.Video) {
    return <VideoIcon {...props} />;
  }
  reportUnexpectedCategoryError(category, props);
  return <ArticlesIcon {...props} text="" />;
};

type Props = {
  doc: FirstClassDocument;
  linkProps: LinkProps;
};

export default function DocumentCard({ doc, linkProps }: Props): JSX.Element {
  const isDarkMode = globalState(
    useCallback((state) => state.webEffectiveTheme === DisplayTheme.Dark, []),
  );
  const faviconUrl = useFaviconUrlFromDoc(doc);
  const rssSourceName = useRssSourceNameForDoc(doc);
  const url = (isDocumentWithThirdPartyUrl(doc) && doc.url) || undefined;
  const originUrl = url ? getUrlDomain(url) : undefined;
  const cardRef = useRef<HTMLElement>(null);
  const isInViewport = useIsInViewport(cardRef);
  const alreadyFetchedContentRef = useRef(false);

  useEffect(() => {
    if (!isInViewport || alreadyFetchedContentRef.current) {
      return;
    }
    fetchDocumentContent([doc.id]);
    alreadyFetchedContentRef.current = true;
  }, [isInViewport, doc.id]);

  let sourceIcon: React.ReactNode;
  let sourceName = getDocumentDomain({
    rssSourceName,
    originUrl,
    siteName: doc.site_name,
  });
  if ([Category.Article, Category.RSS, Category.Video].includes(doc.category)) {
    sourceIcon = (
      <DocumentFaviconOrIcon
        category={doc.category}
        className={styles.articleSourceIcon}
        faviconUrl={faviconUrl}
      />
    );

    if (doc.category === Category.Video) {
      sourceName = 'YouTube';
    }
    if (!sourceName) {
      sourceName = capitalize(doc.category);
    }
  } else {
    let additionalClassName: string;

    if (doc.category === Category.Email) {
      additionalClassName = styles.emailSourceIcon;
      sourceName = 'email';
    } else if (doc.category === Category.EPUB) {
      additionalClassName = styles.bookSourceIcon;
      sourceName = 'book';
    } else if (doc.category === Category.PDF) {
      additionalClassName = styles.pdfSourceIcon;
      if (!sourceName) {
        sourceName = 'PDF';
      }
    } else if (doc.category === Category.Tweet) {
      additionalClassName = styles.tweetSourceIcon;
      sourceName = 'Twitter';
    } else {
      reportUnexpectedCategoryError(doc.category, { doc });
      additionalClassName = '';
      sourceName = 'Unknown';
    }

    sourceIcon = getIconFromCategory(doc.category, {
      className: [styles.sourceIcon, additionalClassName].join(' '),
    });
  }

  let imageContainerContents: React.ReactNode;
  const imageContainerStyles: React.CSSProperties = {};

  const imageContainerBackgroundImage = useMemo(
    () =>
      getRandomItem(
        isDarkMode
          ? [
              galleryBackgroundDark1,
              galleryBackgroundDark2,
              galleryBackgroundDark3,
              galleryBackgroundDark4,
            ]
          : [
              galleryBackgroundLight1,
              galleryBackgroundLight2,
              galleryBackgroundLight3,
              galleryBackgroundLight4,
            ],
      ),
    [isDarkMode],
  );

  const imageUrl = getDocumentOverrideOrReal(doc, 'image_url');

  if (imageUrl) {
    if (doc.category === Category.EPUB) {
      imageContainerContents = (
        <div
          className={styles.bookImage}
          style={{
            backgroundImage: `url("${imageUrl}")`,
          }}
        />
      );
    } else {
      imageContainerStyles.backgroundImage = `url("${imageUrl}"), url("${imageContainerBackgroundImage}")`;
    }
  } else {
    imageContainerContents = getIconFromCategory(doc.category, {
      className: styles.emptyImageContainerIcon,
    });
    imageContainerStyles.backgroundImage = `url("${imageContainerBackgroundImage}")`;
  }

  let timeRemainingSection: JSX.Element;

  const listeningTimeInSeconds = getListeningTime(doc);
  const readingProgress = doc.readingPosition?.scrollDepth ? doc.readingPosition?.scrollDepth * 100 : 0;

  if (typeof listeningTimeInSeconds === 'number') {
    timeRemainingSection = (
      <div className={styles.timeRemaining}>
        <DonutProgressIndicator progressPercentage={readingProgress} />
        <TimeToListen listeningTimeInSeconds={listeningTimeInSeconds} />
      </div>
    );
  } else {
    const readingProgressText = `${readingProgress}% read`;
    if (readingProgress === 100) {
      timeRemainingSection = (
        <CheckmarkInCircleIcon className={styles.readingCompleteIcon} text={readingProgressText} />
      );
    } else {
      timeRemainingSection = (
        <div className={styles.timeRemaining}>
          <DonutProgressIndicator progressPercentage={readingProgress} />
          <TimeLeftToRead suffix="" readingPercent={readingProgress} wordCount={doc.word_count ?? 0} />
        </div>
      );
    }
  }

  const classes = [
    'documentCard',
    styles.documentCard,
    styles[`documentCard--${doc.category.toLowerCase()}`],
  ];

  return (
    <article ref={cardRef} className={classes.join(' ')}>
      <Link className={styles.link} {...linkProps}>
        <div className={styles.imageContainer} style={imageContainerStyles}>
          {imageContainerContents}
        </div>
        <div className={styles.main}>
          <div className={styles.sourceContainer}>
            <div className={styles.sourceImage}>{sourceIcon}</div>
            <div className={styles.sourceName}>{sourceName}</div>
          </div>
          <h1 className={styles.title}>{getDocumentOverrideOrReal(doc, 'title')}</h1>
          <footer className={styles.footer}>
            <p className={styles.author}>
              {getDocumentOverrideOrReal(doc, 'author') || 'Unknown author'}
            </p>
            {timeRemainingSection}
            <StatusDot {...doc} className={styles.statusDot} />
          </footer>
        </div>
      </Link>
    </article>
  );
}
