import React from 'react';

import styles from './SplitButtonGroup.module.css';

export type ButtonVariant = 'unstyled' | 'default';

type Props = {
  children: JSX.Element[];
  className?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (function SplitButtonGroup({ children, className }) {
  const classes = ['splitButtonGroup', styles.splitButtonGroup, className].filter(Boolean);
  return <div className={classes.join(' ')}>{children}</div>;
} as React.FC<Props>);
